import { Link, RouteComponentProps } from '@reach/router';
import { gql } from 'graphql-request';
import React, { FC } from 'react';
import { useGraphQlQuery, useSanityGraphQlQuery } from '../api/backendApi';
import { MenuList, MenuListLink } from '../components/MenuList';
import { PageWrapper } from '../components/PageWrapper';
import { DiscussionTopicsQuery } from './__generated__/DiscussionTopicsQuery';

const DISCUSSION_POSTS_QUERY = gql`
    query DiscussionTopicsQuery {
        discussionTopics {
            nodes {
                id
                name
            }
        }
    }
`;

const SANITY_DISCUSSION_OBJECTS = `
    query SanityDicussionObjectsQuery {
        allProduct {
            _id
            title {
                nb
            }
            slug {
                nb {
                    current
                }
            }
        }
        allContentPage {
            _id
            title {
                nb
            }
            slug {
                nb {
                    current
                }
            }
        }
    }
`;

type SanityObjects = {
    allProduct: {
        _id: string;
        title: {
            nb: string;
        };
        slug: {
            nb: {
                current: string;
            };
        };
    }[];
    allContentPage: {
        _id: string;
        title: {
            nb: string;
        };
        slug: {
            nb: {
                current: string;
            };
        };
    }[];
};

export const Discussions: FC<RouteComponentProps> = ({ children }) => {
    let { isLoading, error, data } = useGraphQlQuery<DiscussionTopicsQuery>(
        DISCUSSION_POSTS_QUERY,
        'discussion'
    );

    let sanityData = useSanityGraphQlQuery<SanityObjects>(
        SANITY_DISCUSSION_OBJECTS,
        'sanity_data_for_discussions'
    );

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error</div>;
    if (!data || !data.discussionTopics) return <div>No data</div>;

    return (
        <PageWrapper>
            <MenuList>
                {data.discussionTopics.nodes.map(topic => (
                    <MenuListLink to={topic.id}>
                        {(sanityData.data &&
                            moreInfoForDiscussionTopic(sanityData.data, topic.id)?.title) ||
                            topic.name ||
                            topic.id}
                    </MenuListLink>
                ))}
            </MenuList>
            {children}
        </PageWrapper>
    );
};

function moreInfoForDiscussionTopic(
    sanity: SanityObjects,
    topic: string
): { title: string; url: string } | undefined {
    if (topic.startsWith('product_')) {
        const sanityId = topic.substr(8);
        const sanityObject = sanity.allProduct.filter(o => o._id === sanityId)[0];
        if (!sanityObject) {
            return undefined;
        }
        const url = `https://www.northernplayground.no/en/shop/${sanityObject.slug.nb.current}`;
        return { title: `Product: ${sanityObject.title.nb}`, url };
    } else if (topic.startsWith('content_page_')) {
        const sanityId = topic.substr(13);
        const sanityObject = sanity.allContentPage.filter(o => o._id === sanityId)[0];
        if (!sanityObject) {
            return undefined;
        }

        const url = `https://www.northernplayground.no/en/content/${sanityObject.slug.nb.current}`;
        return { title: `ContentPage: ${sanityObject.title.nb}`, url };
    }
}
