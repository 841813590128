import { Link, LinkGetProps } from '@reach/router';
import { css, cx } from '@emotion/css';
import React, { FC } from 'react';
import { colors, s } from '../styles';

export const MenuList: FC = ({ children }) => {
    return <div className={css``}>{children}</div>;
};

const itemStyle = css`
    display: block;
    padding: ${s(2)} ${s(3)};
    width: 100%;
    text-decoration: none;
    color: inherit;
    &:hover {
        background: ${colors.lightgrey};
    }

    &.active {
        background: ${colors.lightgrey};
    }
`;

export const MenuListLink: FC<{ to: string; className?: string }> = ({
    children,
    to,
    className,
}) => {
    const isActive = ({ isCurrent }: LinkGetProps) => ({
        className: cx({ [itemStyle]: true, active: isCurrent, [className || '']: true }),
    });

    return (
        <Link getProps={isActive} to={to} className={cx(itemStyle, className)}>
            {children}
        </Link>
    );
};

export const MenuListExternalLink: FC<{ href: string }> = ({ children, href }) => {
    return (
        <a href={href} className={itemStyle}>
            {children}
        </a>
    );
};
