/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { RouteComponentProps } from '@reach/router';
import { sign } from 'crypto';
import React, { FC, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { backendApi } from '../api/backendApi';
import { filledButton, s } from '../styles';

type GetUniqueSignUps = {
    emails: string[];
    signups: {
        email: string;
        centra_item_id: string;
        product: string;
        variant: string;
        size: string;
    }[];
};

type SendSignUp = {
    email: string;
    centra: {
        item_id: string;
        size: string;
    }[];
};

export const ProductNotification: FC<RouteComponentProps> = () => {
    const [emailingList, setEmailingList] = useState<SendSignUp[]>([]);
    const { data, error, isLoading, isFetching } = useQuery('product_notifications', async () => {
        let res = await backendApi.get<GetUniqueSignUps>(`/api/v1/product-notification/sign-ups`);

        return res.data;
    });

    const queryClient = useQueryClient();

    const sendEmails = useMutation(
        async (emailingList: SendSignUp[]) => {
            console.log('emailingList', emailingList);
            return backendApi.post(`/api/v1/product-notification/send-emails`, {
                notify: emailingList,
            });
        },
        {
            onSuccess: () => {
                void queryClient.resetQueries('product_notifications');
                setEmailingList([]);
            },
        }
    );

    if (sendEmails.isLoading)
        return (
            <p
                css={css`
                    margin: ${s(4)} 0;
                    display: flex;
                    justify-content: center;
                `}
            >
                Sending emails...
            </p>
        );

    if (error) return <div>Error</div>;

    if (isLoading)
        return (
            <p
                css={css`
                    margin: ${s(4)} 0;
                    display: flex;
                    justify-content: center;
                `}
            >
                Loading...
            </p>
        );

    if (!data || data.signups.length === 0)
        return (
            <p
                css={css`
                    margin: ${s(4)} 0;
                    display: flex;
                    justify-content: center;
                `}
            >
                No signups
            </p>
        );

    return (
        <div
            css={css`
                margin: ${s(1)} ${s(2)};
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: ${s(3)};
                height: 100vh;
            `}
        >
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    height: 60%;
                    overflow-y: auto;
                `}
            >
                <div
                    css={css`
                        height: 32px;
                    `}
                >
                    {isFetching && (
                        <h2
                            css={css`
                                font-style: italic;
                                line-height: 32px;
                            `}
                        >
                            Laster inn...
                        </h2>
                    )}
                </div>
                <h1
                    css={css`
                        margin-top: 8px;
                        font-size: 22px;
                        font-weight: bold;
                    `}
                >
                    Overview of email and the product variant
                </h1>
                <h2
                    css={css`
                        margin-top: 16px;
                        font-style: italic;
                        line-height: 32px;
                    `}
                >
                    By default, clicking the send-button will send email to everybody in list.
                    <br />
                    Click checkbox if you only want to send email to some people.
                </h2>
                <table
                    css={css`
                        margin-top: 32px;
                        width: 100%;
                        border-collapse: collapse;
                        tr {
                            text-align: left;
                        }

                        th,
                        td {
                            padding-left: 16px;
                        }
                    `}
                >
                    <thead>
                        <tr
                            css={css`
                                font-weight: bold;
                            `}
                        >
                            <th>Email</th>
                            <th>Product</th>
                            <th>Variant</th>
                            <th>Size</th>
                            <th
                                css={css`
                                    padding-left: 42px;
                                `}
                            >
                                Marked
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.signups.map((signup, index) => (
                            <tr css={css``} key={`signup-${index}`}>
                                <td css={css``}>{signup.email}</td>
                                <td css={css``}>{signup.product}</td>
                                <td css={css``}>{signup.variant}</td>
                                <td css={css``}>{signup.size}</td>
                                <td
                                    css={css`
                                        padding-left: 42px;
                                    `}
                                >
                                    <label
                                        css={css`
                                            font-style: italic;
                                        `}
                                    >
                                        <input
                                            type="checkbox"
                                            onClick={event => {
                                                // NOTE
                                                // The goal is to include an email only once, and have a list of the centra objects that the email is attached to.
                                                // Example
                                                // [
                                                //     {
                                                //         email: "test@iterate.no",
                                                //         centra: [
                                                //             {
                                                //                 item_id: 1
                                                //                 size: M
                                                //             },
                                                //             {
                                                //                 item_id: 2
                                                //                 size: M
                                                //             }
                                                //         ]
                                                //     },
                                                //     {
                                                //         email: "test2@iterate.no",
                                                //         centra: [
                                                //             {
                                                //                 item_id: 1
                                                //                 size: M
                                                //             },
                                                //         ]
                                                //     },
                                                // ]

                                                const checked = event.currentTarget.checked;

                                                const centra = {
                                                    item_id: signup.centra_item_id,
                                                    size: signup.size,
                                                };

                                                const prev_included = emailingList.find(
                                                    e => e.email === signup.email
                                                );

                                                if (checked) {
                                                    if (prev_included) {
                                                        // If checked is true, see if email is already included in list and update centra object
                                                        const exclude_prev_included = emailingList.filter(
                                                            e => e.email !== signup.email
                                                        );

                                                        prev_included.centra = [
                                                            centra,
                                                            ...prev_included.centra,
                                                        ];
                                                        setEmailingList([
                                                            ...exclude_prev_included,
                                                            prev_included,
                                                        ]);
                                                    } else {
                                                        // If checked is true and email is not in list, add to emailing list
                                                        const new_signup = {
                                                            email: signup.email,
                                                            centra: [
                                                                {
                                                                    item_id: signup.centra_item_id,
                                                                    size: signup.size,
                                                                },
                                                            ],
                                                        };
                                                        setEmailingList([
                                                            ...emailingList,
                                                            new_signup,
                                                        ]);
                                                    }
                                                } else {
                                                    if (!prev_included?.centra) {
                                                        return;
                                                    }

                                                    if (prev_included.centra.length === 1) {
                                                        setEmailingList(
                                                            emailingList.filter(
                                                                e => e.email !== signup.email
                                                            )
                                                        );
                                                    } else {
                                                        // If checked is false, and there are more than one centra object, remove only this centra object from the list.
                                                        const exclude_prev_included = emailingList.filter(
                                                            e => e.email !== signup.email
                                                        );

                                                        prev_included.centra = prev_included?.centra.filter(
                                                            c =>
                                                                c.item_id !== centra.item_id &&
                                                                c.size !== centra.size
                                                        );

                                                        setEmailingList([
                                                            ...exclude_prev_included,
                                                            prev_included,
                                                        ]);
                                                    }
                                                }
                                            }}
                                        />
                                        &nbsp; Include in emailing list
                                    </label>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div>
                <button
                    css={filledButton}
                    onClick={() => {
                        if (!emailingList || emailingList.length === 0) {
                            // Populate emailing list with every signup

                            const massaged_signups: SendSignUp[] = [];

                            data.signups.forEach(s => {
                                const centra = {
                                    item_id: s.centra_item_id,
                                    size: s.size,
                                };

                                const prev_included = massaged_signups.find(
                                    i => i.email === s.email
                                );

                                if (prev_included) {
                                    prev_included.centra = [centra, ...prev_included.centra];
                                } else {
                                    massaged_signups.push({
                                        email: s.email,
                                        centra: [
                                            {
                                                item_id: s.centra_item_id,
                                                size: s.size,
                                            },
                                        ],
                                    });
                                }
                            });
                            sendEmails.mutate(massaged_signups);
                        } else {
                            sendEmails.mutate(emailingList);
                        }
                    }}
                >
                    Send mail til{' '}
                    {!emailingList || emailingList.length === 0
                        ? 'alle'
                        : `${emailingList.length} pers`}
                </button>
            </div>
        </div>
    );
};
