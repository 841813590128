/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { backendApi } from '../api/backendApi';
import { colors, h1 } from '../styles';

export const Stats: FC = () => {
    let { isLoading, data, error } = useQuery(
        'admin_stats',
        async () => (await backendApi.get<StatsData>('/api/v1/admin/stats')).data
    );

    if (error) {
        return <div>{(error as Error).toString()}</div>;
    }

    if (isLoading || !data) {
        return <div>isLoading...</div>;
    }

    return (
        <div
            css={css`
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(1px, 300px));
                justify-content: center;
                margin: 24px;
                grid-gap: 24px;
            `}
        >
            <Number title="user count" value={data.userCount} />
            <Number title="like count" value={data.likeCount} />
            <Number title="post count" value={data.postCount} />
            <Number title="vote count" value={data.voteCount} />
            <Number title="idea count" value={data.ideaCount} />
            <Number title="cf order count" value={data.cfOrderCount} />

            <NewCount
                first
                title="new users"
                day={data.usersLastDayCount}
                seven={data.usersLast7DayCount}
                thirty={data.usersLast30DayCount}
            />
            <NewCount
                title="new likes"
                day={data.likesLastDayCount}
                seven={data.likesLast7DayCount}
                thirty={data.likesLast30DayCount}
            />
            <NewCount
                title="new posts"
                day={data.postsLastDayCount}
                seven={data.postsLast7DayCount}
                thirty={data.postsLast30DayCount}
            />
            <NewCount
                title="new ideas"
                day={data.ideasLastDayCount}
                seven={data.ideasLast7DayCount}
                thirty={data.ideasLast30DayCount}
            />
            <NewCount
                title="new crowdfunding orders"
                day={data.cfOrdersLastDayCount}
                seven={data.cfOrdersLast7DayCount}
                thirty={data.cfOrdersLast30DayCount}
            />
        </div>
    );
};

const NewCount: FC<{
    title: string;
    day: number;
    seven: number;
    thirty: number;
    first?: boolean;
}> = ({ title, day, seven, thirty, first }) => {
    return (
        <div
            css={css`
                background: ${colors.crowdsourcing};
                padding: 24px;
                color: white;
                text-align: center;
                border-radius: 5px;
                ${first && `grid-column-start: 1`};
                grid-column-end: span 2;
            `}
        >
            <div
                css={css`
                    font-size: 23px;
                    font-variant: small-caps;
                `}
            >
                {title}
            </div>
            <div
                css={css`
                    display: grid;
                    margin-top: 16px;
                    grid-template-rows: auto auto;
                    grid-auto-flow: column;
                    .header {
                        font-size: 20px;
                        text-transform: uppercase;
                        color: #eee;
                    }

                    .value {
                        ${h1};
                    }
                `}
            >
                <div className="header">1 day</div>
                <div className="value">{day}</div>
                <div className="header">7 day</div>
                <div className="value">{seven}</div>
                <div className="header">30 days</div>
                <div className="value">{thirty}</div>
            </div>
        </div>
    );
};

const Number: FC<{ title: string; value: number; first?: boolean }> = ({ title, value, first }) => {
    return (
        <div
            css={css`
                background: ${colors.crowdfunding};
                padding: 24px;
                color: white;
                text-align: center;
                border-radius: 5px;
                ${first && `grid-column: 1`};
            `}
        >
            <div
                css={css`
                    font-size: 23px;
                    font-variant: small-caps;
                `}
            >
                {title}
            </div>
            <div
                css={css`
                    ${h1};
                    margin-top: 16px;
                `}
            >
                {value}
            </div>
        </div>
    );
};

type StatsData = {
    userCount: number;
    usersLastDayCount: number;
    usersLast7DayCount: number;
    usersLast30DayCount: number;
    likeCount: number;
    likesLastDayCount: number;
    likesLast7DayCount: number;
    likesLast30DayCount: number;
    postCount: number;
    postsLastDayCount: number;
    postsLast7DayCount: number;
    postsLast30DayCount: number;
    voteCount: number;
    votesLastDayCount: number;
    votesLast7DayCount: number;
    votesLast30DayCount: number;
    ideaCount: number;
    ideasLastDayCount: number;
    ideasLast7DayCount: number;
    ideasLast30DayCount: number;
    cfOrderCount: number;
    cfOrdersLastDayCount: number;
    cfOrdersLast7DayCount: number;
    cfOrdersLast30DayCount: number;
};
