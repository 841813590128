/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { RouteComponentProps, useParams } from '@reach/router';
import { gql } from 'graphql-request';
import React, { FC } from 'react';
import { useQueryClient } from 'react-query';
import { backendApi, graphqlClient, useGraphQlQuery } from '../api/backendApi';
import { useErrorHandler } from '../lib/utils';
import { colors, filledButton, h2, s } from '../styles';

const USER_QUERY = gql`
    query User($userId: Int!) {
        user(id: $userId) {
            admin
            birthYear
            country
            email
            fullName
            id
            postcode
            preferredFit
            profilePicture
        }
    }
`;

type UserQuery = {
    user: {
        id: number;
        admin: boolean;
        birthYear: number;
        country: string;
        email: string;
        fullName: string;
        postcode: string;
        preferredFit: string;
        profilePicture: string;
    };
};

const Property: FC<{ name: string }> = ({ name, children }) => (
    <div
        css={css`
            padding: ${s(1)} 0;
            display: flex;

            &:hover {
                background: ${colors.lightgrey};
            }
        `}
    >
        <div
            css={css`
                width: 200px;
            `}
        >
            {name}
        </div>
        <strong>{children}</strong>
    </div>
);

export const User: FC<RouteComponentProps> = () => {
    const { userId } = useParams<{ userId: string }>();
    const queryClient = useQueryClient();
    const handleError = useErrorHandler();
    const { isLoading, error, data } = useGraphQlQuery<UserQuery>(USER_QUERY, 'user', {
        userId: parseInt(userId) || -1,
    });

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error</div>;
    if (!data) return <div>No such user</div>;

    const user = data.user;

    if (!user) return <div>No such user</div>;

    return (
        <div
            css={css`
                padding: ${s(2)};
            `}
        >
            <h1 css={h2}>{user.fullName || user.email}</h1>
            <div
                css={css`
                    display: flex;
                    margin-bottom: ${s(2)};
                    button {
                        ${filledButton};
                        margin-right: ${s(1)};

                        &.delete {
                            background: ${colors.error};
                        }
                    }
                `}
            >
                <button
                    onClick={() =>
                        setAdmin(user.id, !user.admin)
                            .then(() => {
                                void queryClient.invalidateQueries(['user']);
                                void queryClient.invalidateQueries(['all_users']);
                            })
                            .catch(handleError)
                    }
                >
                    Toggle Admin
                </button>
                <button
                    className="delete"
                    onClick={() =>
                        deleteUser(user.id)
                            .then(() => {
                                void queryClient.invalidateQueries(['user']);
                                void queryClient.invalidateQueries(['all_users']);
                            })
                            .catch(handleError)
                    }
                >
                    Delete
                </button>
                <button
                    className="delete"
                    onClick={() =>
                        deletePosts(user.id)
                            .then(() => {
                                void queryClient.invalidateQueries(['user']);
                            })
                            .catch(handleError)
                    }
                >
                    Delete Posts
                </button>
            </div>
            <Property name="ID">{user.id}</Property>
            <Property name="Admin">{user.admin.toString()}</Property>
            <Property name="Birth Year">{user.birthYear}</Property>
            <Property name="Country">{user.country}</Property>
            <Property name="Email">{user.email}</Property>
            <Property name="Name">{user.fullName}</Property>
            <Property name="Postcode">{user.postcode}</Property>
            <Property name="Preferred Fit">{user.preferredFit}</Property>
            <Property name="Profile Picture">
                {user.profilePicture && (
                    <img
                        src={`${process.env.REACT_APP_IMAGE_HOST}${user.profilePicture}?height=300`}
                    />
                )}
            </Property>
        </div>
    );
};

async function deleteUser(userId: number) {
    if (window.confirm(`Are you sure you want to delete the user?`)) {
        await graphqlClient.request(
            gql`
            mutation DeleteUser($userId: Int!) {
                updateUser(
                    input: {
                        patch: {
                            admin: false
                            birthYear: null
                            country: null
                            email: "deleteduser${userId}"
                            fullName: "Slettet konto"
                            passwordHash: "deleted-user"
                            postcode: null
                            preferredFit: null
                            profilePicture: null
                        }
                        id: $userId
                    }
                ) {
                    clientMutationId
                }
            }
        `,
            { userId }
        );
    }
}

async function deletePosts(userId: number) {
    if (window.confirm(`Are you sure you want to delete all posts for the user?`)) {
        await backendApi.delete(`/api/v1/admin/user/${userId}/posts`);
    }
}

async function setAdmin(userId: number, isAdmin: boolean) {
    await graphqlClient.request(
        gql`
            mutation SetAdmin($userId: Int!, $isAdmin: Boolean!) {
                updateUser(input: { patch: { admin: $isAdmin }, id: $userId }) {
                    clientMutationId
                }
            }
        `,
        { userId, isAdmin }
    );
}
