/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { RouteComponentProps, useParams } from '@reach/router';
import { gql } from 'graphql-request';
import React, { FC } from 'react';
import { useGraphQlQuery } from '../api/backendApi';
import { h4, s, space } from '../styles';
import { IdeaQuery } from './__generated__/IdeaQuery';

const IDEA_QUERY = gql`
    query IdeaQuery($ideaId: Int!) {
        idea(id: $ideaId) {
            id
            content
            images
            userByCreatedBy {
                fullName
                email
            }
        }
    }
`;

export const Idea: FC<RouteComponentProps> = () => {
    let { ideaId } = useParams<{ ideaId: string }>();
    let { isLoading, error, data } = useGraphQlQuery<IdeaQuery>(IDEA_QUERY, 'crowdfunding_order', {
        ideaId: parseInt(ideaId) || -1,
    });

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error</div>;
    if (!data) return <div>No such idea</div>;

    let idea = data.idea;
    let user = idea?.userByCreatedBy;

    if (!idea || !user) return <div>No such order</div>;

    return (
        <div
            css={css`
                padding: ${s(1)} ${s(2)};
                h1 {
                    ${h4};
                    font-weight: 600;
                }
            `}
        >
            <h1>
                Idea by {user.fullName} ({user.email})
            </h1>
            <div
                css={css`
                    margin-bottom: ${space(6)};
                `}
            >
                {idea.content.split('\n\n').map((paragraph, idx) => (
                    <p
                        css={css`
                            margin: ${space(2)} 0;
                        `}
                        key={idx}
                    >
                        {paragraph.split('\n').map((line, idx) => (
                            <React.Fragment key={idx}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </p>
                ))}
            </div>
            <div
                css={css`
                    display: flex;
                    flex-wrap: wrap;
                `}
            >
                {idea.images.map(image => (
                    <a href={`${process.env.REACT_APP_IMAGE_HOST}${image}`}>
                        <img
                            css={css`
                                border-radius: 5px;
                                margin-right: ${space(3)};
                                cursor: pointer;
                            `}
                            key={image}
                            src={`${process.env.REACT_APP_IMAGE_HOST}${image}?height=200`}
                        />
                    </a>
                ))}
            </div>
        </div>
    );
};
