import React, { FC } from 'react';

import { RouteComponentProps } from '@reach/router';
import { gql } from 'graphql-request';
import { useGraphQlQuery } from '../api/backendApi';
import { MenuList, MenuListLink } from '../components/MenuList';
import { PageWrapper } from '../components/PageWrapper';
import { IdeasQuery } from './__generated__/IdeasQuery';
import { format } from 'date-fns';

const IDEAS_QUERY = gql`
    query IdeasQuery {
        ideas {
            nodes {
                id
                createdAt
                userByCreatedBy {
                    fullName
                }
            }
        }
    }
`;

export const Ideas: FC<RouteComponentProps> = ({ children }) => {
    let { isLoading, error, data } = useGraphQlQuery<IdeasQuery>(IDEAS_QUERY, 'ideas');

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error</div>;
    if (!data || !data.ideas) return <div>No data</div>;

    if (data.ideas.nodes.length === 0) {
        return <div>No ideas</div>;
    }

    return (
        <PageWrapper>
            <MenuList>
                {data.ideas.nodes.map(idea => (
                    <MenuListLink to={idea.id.toString()}>
                        {format(new Date(idea.createdAt as string), 'dd.MM.yyyy')}{' '}
                        {idea.userByCreatedBy?.fullName}
                    </MenuListLink>
                ))}
            </MenuList>
            {children}
        </PageWrapper>
    );
};
