import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from '@sentry/react';

import './reset.css';
import './global.css';
import { QueryClient, QueryClientProvider } from 'react-query';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://117cd731aba04ffa90f1dfe76c92ed2a@o402078.ingest.sentry.io/5478595',

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
}

const queryClient = new QueryClient();

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <App />
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
