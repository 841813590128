import { css } from '@emotion/react';

export const s = (size: number): string => `${size * 8}px`;
export const space = s;

export const colors = {
    black: '#2b2929',
    white: '#fff',
    grey: '#737373',
    lightgrey: '#d6d6d6',
    error: '#d80134',
    crowdsourcing: '#0D6490',
    crowdfunding: '#B13D0E',
};

// margins and sizes from https://type-scale.com/
export const h1 = css`
    font-size: 3.052em;
    margin: 0 0 1.05rem;
`;

export const h2 = css`
    font-size: 2.441em;
    margin: 0 0 1.05rem;
`;

export const h3 = css`
    font-size: 1.953em;
    margin: 0 0 1.05rem;
`;

export const h4 = css`
    font-size: 1.563em;
    margin: 0 0 1.05rem;
`;

export const h5 = css`
    font-size: 1.25em;
    margin: 0 0 1.05rem;
`;

export const smallText = css`
    font-size: 0.8em;
`;

export const labelStyle = css`
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
`;

export const secondaryButton = css`
    border-radius: 5px;
    padding: 0 ${s(1)};
    line-height: ${s(4)};
    cursor: pointer;
    border: 2px solid rgba(0, 0, 0, 0.2);

    &:hover {
        background: ${colors.lightgrey};
    }

    :disabled {
        background: ${colors.grey};
    }
`;

export const filledButton = css`
    border-radius: 5px;
    padding: 0 ${s(1)};
    line-height: ${s(4)};
    background: ${colors.black};
    color: ${colors.white};
    cursor: pointer;

    :disabled {
        background: ${colors.grey};
    }
`;
