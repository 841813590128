/** @jsxImportSource @emotion/react */

import { css, cx } from '@emotion/css';
import { RouteComponentProps, Router } from '@reach/router';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { gql } from 'graphql-request';
import { darken } from 'polished';
import React, { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { backendApi, useGraphQlQuery } from '../api/backendApi';
import { MenuList, MenuListLink } from '../components/MenuList';
import { PageWrapper } from '../components/PageWrapper';
import { colors, filledButton, s } from '../styles';
import { CrowdfundingOrder } from './CrowdfundingOrder';
import { CrowdfundingOrdersQuery } from './__generated__/CrowdfundingOrdersQuery';

const ORDERS_QUERY = gql`
    query CrowdfundingOrdersQuery($centraId: Int) {
        crowdfundingOrders(condition: { productId: $centraId }) {
            nodes {
                id
                cancelled
                centraOrderId
                invoiceAddress
                deliveryAddress
                lines: crowdfundingOrderLinesByOrderId {
                    nodes {
                        unitPrice
                        qty
                        sku
                    }
                }
                shippingValue
                adyenPaymentStatus
                payment {
                    currency
                }
            }
        }
    }
`;

async function doFinalizeOrders(centraId: number) {
    if (window.confirm('Are you sure you want to confirm the order? This cannot be undone.')) {
        try {
            let result = (
                await backendApi.post<{ success: number; errorLog: string[] }>(
                    `/api/v1/admin/finalize_crowdfunding_payments_for_product_id/${centraId}`
                )
            ).data;

            alert(
                `Finalized ${result.success} orders. ${
                    result.errorLog.length
                } errors: \n\n${result.errorLog.join('\n')}`
            );
        } catch (err) {
            Sentry.captureException(err);
            alert((err as AxiosError).message);
        }
    }
}

export const CrowdfundingOrders: FC<RouteComponentProps & { centraId?: string }> = ({
    centraId,
}) => {
    let centraIdInt = centraId && parseInt(centraId, 10);
    let { isLoading, error, data } = useGraphQlQuery<CrowdfundingOrdersQuery>(
        ORDERS_QUERY,
        'crowdfunding_orders',
        { centraId: centraIdInt }
    );
    const queryClient = useQueryClient();

    let finalizeOrders = useMutation(async () => {
        try {
            await doFinalizeOrders(centraIdInt || 0);
            void queryClient.invalidateQueries('crowdfunding_order');
            void queryClient.invalidateQueries('crowdfunding_orders');
        } catch (err) {
            return console.error(`Error calling finalizeOrders: ${err}`);
        }
    });

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error</div>;
    if (!data || !data.crowdfundingOrders) return <div>No data</div>;

    if (data.crowdfundingOrders.nodes.length === 0) {
        return <div>No Crowdfunding Orders</div>;
    }

    return (
        <PageWrapper>
            <MenuList>
                <div
                    className={css`
                        display: flex;
                        align-items: center;
                        margin: ${s(1)};
                        & > * {
                            margin: ${s(1)};
                        }
                    `}
                >
                    <div>{data.crowdfundingOrders.nodes.length} orders</div>
                    <button
                        css={filledButton}
                        disabled={finalizeOrders.isLoading}
                        onClick={() => finalizeOrders.mutate()}
                    >
                        {finalizeOrders.isLoading ? 'Working...' : 'Finalize all orders'}
                    </button>
                </div>
                {data.crowdfundingOrders.nodes.map(order => (
                    <MenuListLink
                        className={cx({
                            [css`
                                background: ${colors.error};

                                &:hover {
                                    background: ${darken(0.1, colors.error)};
                                }
                            `]: order.cancelled,
                        })}
                        to={order.id.toString()}
                    >
                        <div>
                            {order.id} {order.invoiceAddress.firstName}{' '}
                            {order.invoiceAddress.lastName}
                        </div>
                        {order.centraOrderId && (
                            <div
                                className={css`
                                    color: ${colors.grey};
                                `}
                            >
                                Centra: {order.centraOrderId} | {order.adyenPaymentStatus}
                            </div>
                        )}
                    </MenuListLink>
                ))}
            </MenuList>
            <Router>
                <CrowdfundingOrder path=":orderId" />
            </Router>
        </PageWrapper>
    );
};
