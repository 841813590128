import { useQuery } from 'react-query';
import { Link, RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';
import { MenuList, MenuListLink } from '../components/MenuList';
import { PageWrapper } from '../components/PageWrapper';
import { graphqlClient, sanityGraphqlClient, useGraphQlQuery } from '../api/backendApi';
import { gql } from 'graphql-request';
import { css, cx } from '@emotion/css';
import { colors } from '../styles';
import { darken } from 'polished';

const USERS_QUERY = gql`
    query Users {
        users {
            nodes {
                id
                email
                fullName
                admin
            }
        }
    }
`;

type UsersQuery = {
    users: {
        nodes: Array<{
            id: number;
            email: string;
            fullName: string;
            admin: boolean;
        }>;
    };
};

export const Users: FC<RouteComponentProps> = ({ children }) => {
    let { isLoading, error, data } = useGraphQlQuery<UsersQuery>(USERS_QUERY, 'all_users');

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.toString()}</div>;
    if (!data) return <div>No data</div>;

    return (
        <PageWrapper>
            <MenuList>
                {data.users.nodes.map(user => (
                    <MenuListLink
                        to={`${user.id}`}
                        className={cx({
                            [css`
                                background: ${colors.error};

                                &:hover {
                                    background: ${darken(0.1, colors.error)};
                                }
                            `]: user.admin,
                        })}
                    >
                        <div>
                            <span
                                className={css`
                                    font-weight: 700;
                                    color: #6b6262;
                                `}
                            >
                                {user.id}
                            </span>{' '}
                            {user.fullName}
                        </div>
                        <div
                            className={css`
                                color: #444;
                            `}
                        >
                            {user.email}
                        </div>
                    </MenuListLink>
                ))}
            </MenuList>
            {children}
        </PageWrapper>
    );
};
