/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Link, RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';
import { Stats } from '../components/Stats';
import { colors, s } from '../styles';

export const Home: FC<RouteComponentProps> = () => {
    let pages = [
        {
            name: 'Crowdfunding Orders',
            url: '/crowdfunding-orders',
        },
        {
            name: 'Discussions',
            url: '/discussions',
        },
        {
            name: 'Products',
            url: '/products',
        },
        {
            name: 'Users',
            url: '/users',
        },
        {
            name: 'Ideas',
            url: '/ideas',
        },
        {
            name: 'Preorders ready to ship',
            url: '/ship-preorders',
        },
        {
            name: 'Product Notification',
            url: '/product-notification',
        },
    ];

    return (
        <div>
            <div
                css={css`
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                `}
            >
                {pages.map(page => (
                    <Link
                        css={css`
                            width: 100%;
                            max-width: 400px;
                            background: ${colors.black};
                            color: ${colors.white};
                            text-decoration: none;
                            margin: ${s(5)};
                            padding: ${s(4)};
                            text-align: center;
                            border-radius: 5px;
                            font-weight: 600;
                            transition: transform 100ms ease-out;

                            &:hover {
                                transform: translate3d(0, -8px, 0);
                            }
                        `}
                        to={page.url}
                    >
                        {page.name}
                    </Link>
                ))}
            </div>
            <Stats />
        </div>
    );
};
