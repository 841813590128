/** @jsxImportSource @emotion/react */

import { gql } from 'graphql-request';
import React, { Dispatch, useReducer } from 'react';
import { graphqlClient } from '../api/backendApi';
import { filledButton, secondaryButton } from '../styles';

let NEXT_ID = 0;

type PollState = {
    question: string;
    alternatives: {
        id: number;
        text: string;
        color: string;
    }[];
};

const EMPTY_POLL: PollState = {
    question: '',
    alternatives: [
        {
            id: NEXT_ID++,
            text: '',
            color: 'black',
        },
    ],
};

type PollAction =
    | {
          type: 'add_alternative';
      }
    | {
          type: 'remove_alternative';
          idx: number;
      }
    | { type: 'set_alternative_text'; text: string; idx: number }
    | { type: 'set_alternative_color'; color: string; idx: number }
    | { type: 'set_question'; text: string }
    | { type: 'reset_to_new' };

const CREATE_POLL = gql`
    mutation CreatePoll($question: String!) {
        createPoll(input: { poll: { question: $question } }) {
            poll {
                id
            }
        }
    }
`;

const CREATE_ALTERNATIVE = gql`
    mutation CreatePollAlternative($pollId: Int!, $text: String!, $color: String!) {
        createPollAlternative(
            input: { pollAlternative: { pollId: $pollId, text: $text, color: $color } }
        ) {
            pollAlternative {
                id
            }
        }
    }
`;

export async function savePoll(poll: PollState): Promise<number> {
    const newPoll = await graphqlClient.request(CREATE_POLL, { question: poll.question });
    console.log(newPoll);
    const pollId: number = newPoll.createPoll.poll.id;

    for (const alternative of poll.alternatives) {
        await graphqlClient.request(CREATE_ALTERNATIVE, {
            pollId,
            text: alternative.text,
            color: alternative.color,
        });
    }

    return pollId;
}

function reducer(state: PollState, action: PollAction) {
    switch (action.type) {
        case 'reset_to_new':
            return EMPTY_POLL;
        case 'add_alternative':
            return {
                ...state,
                alternatives: [
                    ...state.alternatives,
                    {
                        id: NEXT_ID++,
                        text: '',
                        color: 'black',
                    },
                ],
            };
        case 'remove_alternative': {
            let alternatives = [...state.alternatives];
            alternatives.splice(action.idx, 1);
            return {
                ...state,
                alternatives,
            };
        }
        case 'set_alternative_text': {
            let alternatives = [...state.alternatives];
            alternatives[action.idx] = {
                ...alternatives[action.idx],
                text: action.text,
            };
            return {
                ...state,
                alternatives,
            };
        }
        case 'set_alternative_color': {
            let alternatives = [...state.alternatives];
            alternatives[action.idx] = {
                ...alternatives[action.idx],
                color: action.color,
            };
            return {
                ...state,
                alternatives,
            };
        }
        case 'set_question': {
            return {
                ...state,
                question: action.text,
            };
        }
        default:
            throw new Error(`Unknown action type`);
    }
}

export function useNewPoll(): [PollState, Dispatch<PollAction>] {
    let [state, dispatch] = useReducer(reducer, EMPTY_POLL);

    return [state, dispatch];
}

export const PollEdit: React.FC<{
    state: PollState;
    dispatch: Dispatch<PollAction>;
    showQuestion?: boolean;
    showColor?: boolean;
}> = ({ state, dispatch, showQuestion = true, showColor = false }) => {
    return (
        <div>
            {showQuestion && (
                <label>
                    Question:
                    <input
                        value={state.question}
                        onChange={ev => dispatch({ type: 'set_question', text: ev.target.value })}
                    />
                </label>
            )}
            <div>Alternatives</div>
            {state.alternatives.map((alternative, idx) => (
                <div key={alternative.id}>
                    <input
                        value={alternative.text}
                        onChange={ev =>
                            dispatch({ type: 'set_alternative_text', text: ev.target.value, idx })
                        }
                    />
                    {showColor && (
                        <select
                            value={alternative.color}
                            onChange={ev =>
                                dispatch({
                                    type: 'set_alternative_color',
                                    color: ev.target.value,
                                    idx,
                                })
                            }
                        >
                            <option value="black">Black</option>
                            <option value="green">Green</option>
                            <option value="red">Red</option>
                        </select>
                    )}
                    <button
                        css={secondaryButton}
                        onClick={ev => {
                            ev.preventDefault();
                            dispatch({ type: 'remove_alternative', idx });
                        }}
                    >
                        delete
                    </button>
                </div>
            ))}
            <button
                css={filledButton}
                onClick={ev => {
                    ev.preventDefault();
                    dispatch({ type: 'add_alternative' });
                }}
            >
                Add alternative
            </button>
        </div>
    );
};
