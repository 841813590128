/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { FC, useContext } from 'react';
import { filledButton, secondaryButton, s, colors, labelStyle } from '../styles';

const FormContext = React.createContext<{
    submitAttempted: boolean;
} | null>(null);

export const Form: FC<{ onSubmit: () => void; error?: Error | false; className?: string }> = ({
    children,
    onSubmit,
    error,
    className,
}) => {
    const [submitAttempted, setSubmitAttempted] = React.useState(false);

    return (
        <FormContext.Provider value={{ submitAttempted }}>
            <form
                className={className}
                onSubmit={ev => {
                    ev.preventDefault();
                    setSubmitAttempted(true);
                    onSubmit();
                }}
                method="post"
                css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                `}
            >
                {error && (
                    <div
                        role="alert"
                        css={css`
                            padding: ${s(1)} ${s(2)};
                            background: ${colors.error};
                            color: white;
                            border-radius: 5px;
                            max-width: 605px;
                            margin: ${s(2)} 0;
                        `}
                    >
                        {error.toString()}
                    </div>
                )}
                {children}
            </form>
        </FormContext.Provider>
    );
};

export const Field: FC<{ label: string; error?: string | false }> = ({
    label,
    error,
    children,
}) => {
    let context = useContext(FormContext);

    if (!context) {
        throw new Error('Field needs to be inside a Form');
    }
    let { submitAttempted } = context;
    let showError = error && submitAttempted;

    return (
        <label
            css={css`
                display: block;
                ${labelStyle};
                margin-bottom: ${s(4)};

                input {
                    display: block;
                    width: 100%;
                    border: 2px solid #d6d6d6;
                    border-radius: 3px;
                    padding: ${s(1)} ${s(2)};
                    font-size: 18px;

                    ${showError &&
                    css`
                        border-color: ${colors.error};
                    `}
                }
            `}
        >
            {label}
            {children}
            {showError && (
                <div
                    role="alert"
                    css={css`
                        color: ${colors.error};
                    `}
                >
                    {error}
                </div>
            )}
        </label>
    );
};

const RadioButtonsContext = React.createContext<{
    name: string;
    value: string;
    onChange: (value: string) => void;
} | null>(null);

export const RadioButtons: FC<{
    label: string;
    name: string;
    value: string;
    onChange: (value: string) => void;
}> = ({ label, children, name, onChange, value }) => {
    return (
        <RadioButtonsContext.Provider value={{ name, onChange, value }}>
            <div>
                <div
                    css={css`
                        margin-bottom: ${s(1)};
                    `}
                >
                    {label}
                </div>
                <div
                    css={css`
                        display: flex;
                        margin-bottom: ${s(4)};
                    `}
                >
                    {children}
                </div>
            </div>
        </RadioButtonsContext.Provider>
    );
};

export const RadioButton: FC<{
    label: string;
    value: string;
}> = ({ label, value }) => {
    let context = React.useContext(RadioButtonsContext);
    if (!context) {
        throw new Error('RadioButton needs to be inside a RadioButtons');
    }
    let checked = context.value === value;

    return (
        <label
            css={css`
                display: block;
                ${checked ? filledButton : secondaryButton};
                margin-right: ${s(2)};
            `}
        >
            {label}
            <input
                css={css`
                    /* Hide the input, we then style the label. Note that we still want the checkbox,
                    this is good accessability */
                    appearance: none;
                    opacity: 0;
                    position: absolute;
                    left: 9999px;
                `}
                type="radio"
                name={context.name}
                checked={checked}
                onChange={() => context?.onChange(value)}
            />
        </label>
    );
};

export const Buttons: FC = ({ children }) => {
    return (
        <div
            css={css`
                align-self: flex-end;
                margin-bottom: ${s(2)};
            `}
        >
            {children}
        </div>
    );
};

export const Button: FC<
    { secondary?: boolean } & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ secondary, ...props }) => {
    return (
        <button
            {...props}
            css={css`
                ${secondary ? secondaryButton : filledButton};
                margin-right: ${s(1)};
            `}
        />
    );
};
