import React from 'react';

export function defaultDict<T>(defaultInit: { new (): T }): Record<string, T> {
    return new Proxy(
        {},
        {
            get: (target: { [key: string]: T }, key: string) => {
                return key in target ? target[key] : (target[key] = new defaultInit());
            },
        }
    );
}

export function useErrorHandler(givenError?: Error) {
    const [error, setError] = React.useState(null);
    if (givenError) throw givenError;
    if (error) throw error;
    return setError;
}
