/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Link, Router } from '@reach/router';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import React, { FC } from 'react';
import { backendApi } from './api/backendApi';
import { useDataApi } from './api/useDataApi';
import { Login } from './components/Login';
import { CrowdfundingOrders } from './pages/CrowdfundingOrders';
import { Discussions } from './pages/Discussions';
import { DiscussionTopic } from './pages/DiscussionTopic';
import { Home } from './pages/Home';
import { Idea } from './pages/Idea';
import { Ideas } from './pages/Ideas';
import { NoSuchRoute } from './pages/NoSuchRoute';
import { Product } from './pages/Product';
import { ProductNotification } from './pages/ProductNotification';
import { Products } from './pages/Products';
import { ShipPreorders } from './pages/ShipPreorders';
import { User } from './pages/User';
import { Users } from './pages/Users';
import { colors, h3, s, secondaryButton } from './styles';

type LoginStatus =
    | {
          loading: true;
          loggedIn: false;
          error: null;
      }
    | {
          loading: false;
          loggedIn: boolean;
          error: null;
      }
    | {
          loading: false;
          loggedIn: false;
          error: AxiosError;
      };

function useLoginStatus(): [LoginStatus, () => void] {
    let [me, refetch] = useDataApi('/api/v1/users/me');

    if (me.isLoading) {
        return [
            {
                loading: true,
                loggedIn: false,
                error: null,
            },
            refetch,
        ];
    }

    if (me.error) {
        if (me.error.response?.status === 401) {
            return [
                {
                    loading: false,
                    loggedIn: false,
                    error: null,
                },
                refetch,
            ];
        } else if (me.error.response?.status === 403) {
            return [
                {
                    loading: false,
                    loggedIn: false,
                    error: null,
                },
                refetch,
            ];
        } else {
            return [
                {
                    loading: false,
                    loggedIn: false,
                    error: me.error,
                },
                refetch,
            ];
        }
    }

    return [
        {
            loading: false,
            loggedIn: true,
            error: null,
        },
        refetch,
    ];
}

function App() {
    let [loginStatus, refetchLogin] = useLoginStatus();

    function logout() {
        backendApi
            .post('/api/v1/logout')
            .then(() => refetchLogin())
            .catch(error => console.error(error));
    }

    return (
        <div>
            <AppBar>
                <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
                    <div
                        css={css`
                            ${h3};
                            font-weight: 700;
                            margin: 0;
                        `}
                    >
                        Northern Playground Admin
                    </div>
                </Link>
                {loginStatus.loggedIn && (
                    <button css={secondaryButton} onClick={() => logout()}>
                        logout
                    </button>
                )}
            </AppBar>
            <div>
                <AppInner loginStatus={loginStatus} refetchLogin={refetchLogin} />
            </div>
        </div>
    );
}

const AppBar: FC = ({ children }) => (
    <header
        css={css`
            display: flex;
            align-items: center;
            background: white;
            border-bottom: 1px solid ${colors.grey};
            justify-content: space-between;
            padding: ${s(1)} ${s(4)};
            z-index: 1;
        `}
    >
        {children}
    </header>
);

const AppInner: FC<{ loginStatus: LoginStatus; refetchLogin: () => void }> = ({
    loginStatus,
    refetchLogin,
}) => {
    if (loginStatus.loading) {
        return <div>Loading...</div>;
    }

    if (loginStatus.error) {
        return (
            <div>
                {loginStatus.error.response?.status} {loginStatus.error.message}
            </div>
        );
    }

    if (!loginStatus.loggedIn) {
        return <Login onSuccess={refetchLogin} />;
    }

    return (
        <Sentry.ErrorBoundary
            fallback={<p>'An error has occured'</p>}
            showDialog
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
                window.location.reload();
            }}
        >
            <Router>
                <Home path="/" />
                <Discussions path="/discussions">
                    <DiscussionTopic path=":topicId" />
                </Discussions>
                <Users path="/users">
                    <User path=":userId" />
                </Users>
                <Products path="/products">
                    <Product path=":sanityId/*" />
                </Products>
                <Ideas path="/ideas">
                    <Idea path=":ideaId" />
                </Ideas>
                <CrowdfundingOrders path="crowdfunding-orders/*" />
                <ShipPreorders path="ship-preorders" />
                <ProductNotification path="product-notification" />
                <NoSuchRoute default />
            </Router>
        </Sentry.ErrorBoundary>
    );
};

export default App;
