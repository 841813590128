/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { RouteComponentProps } from '@reach/router';
import React, { FC, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { backendApi } from '../api/backendApi';
import { filledButton, s, secondaryButton } from '../styles';

export const ShipPreorders: FC<RouteComponentProps> = () => {
    const { data, error, isLoading, isFetching, refetch } = useQuery(
        ['shippable-preorders'],
        shippablePreorders,
        {
            refetchOnWindowFocus: false,
        }
    );
    const [selectedOrders, setSelectedOrders] = useState(() => new Set<string>());
    const queryClient = useQueryClient();

    const createShipments = useMutation(
        async () =>
            backendApi.post(`/api/v1/preorders-back-in-stock/create-shipments`, null, {
                params: {
                    orderIds: JSON.stringify(Array.from(selectedOrders).map(num => parseInt(num))),
                },
            }),
        {
            onSuccess: () => {
                void queryClient.invalidateQueries('shippable-preorders');
            },
        }
    );

    const allOrderIds = useMemo(() => new Set(data?.map(order => order.order)), [data]);

    if (error) {
        throw error;
    }

    if (isLoading || !data) {
        return <div>Loading...</div>;
    }

    return (
        <div css={{ marginBottom: s(10) }}>
            <div
                css={{
                    margin: 16,
                    display: 'grid',
                    gridAutoFlow: 'column',
                    justifyContent: 'start',
                    gridGap: 8,
                }}
            >
                <button css={filledButton} onClick={() => refetch()}>
                    Refresh
                </button>
                {createShipments.isLoading ? (
                    <div>Creating shipment.... Please wait</div>
                ) : (
                    <button
                        css={filledButton}
                        onClick={() => {
                            if (selectedOrders.size === 0) {
                                alert('You need to select at least one order');
                                return;
                            }
                            createShipments.mutate();
                        }}
                    >
                        Create shipments
                    </button>
                )}
            </div>
            {isFetching && <div>Refreshing...</div>}
            <table
                css={css`
                    margin: 16px;
                    border-spacing: 0;
                    border-collapse: collapse;
                    table {
                        border-spacing: 0;
                        border-collapse: collapse;
                    }
                    & > tr > td,
                    & > tr > th {
                        border: 1px solid #444;
                        text-align: left;
                    }
                    & > tr > th {
                        font-weight: 600;
                    }
                `}
            >
                <tr>
                    <th
                        css={css`
                            vertical-align: middle;
                        `}
                    >
                        <input
                            type="checkbox"
                            checked={eqSet(selectedOrders, allOrderIds)}
                            onChange={ev => {
                                if (ev.target.checked) {
                                    setSelectedOrders(allOrderIds);
                                } else {
                                    setSelectedOrders(new Set());
                                }
                            }}
                            css={css`
                                -webkit-appearance: none;
                                width: 30px;
                                height: 30px;
                                background: white;
                                border-radius: 2px;
                                border: 2px solid black;

                                &:checked {
                                    background: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E")
                                        no-repeat center/80%;
                                }
                            `}
                        />
                    </th>
                    <th css={{ padding: '8px 16px' }}>id</th>
                    <th css={{ padding: '8px 16px' }}>Products</th>
                    <th>&nbsp;</th>
                </tr>
                {data.map(shipment => (
                    <Shipment
                        shipment={shipment}
                        selected={selectedOrders.has(shipment.order)}
                        setSelected={val => {
                            setSelectedOrders(old => {
                                let newSet = new Set(old);
                                if (val) {
                                    newSet.add(shipment.order);
                                } else {
                                    newSet.delete(shipment.order);
                                }
                                return newSet;
                            });
                        }}
                    />
                ))}
            </table>
        </div>
    );
};

const Shipment: FC<{
    shipment: Shipment;
    selected: boolean;
    setSelected: (s: boolean) => void;
}> = ({ shipment, selected, setSelected }) => {
    const queryClient = useQueryClient();

    const createShipment = useMutation(
        async () =>
            backendApi.post(`/api/v1/preorders-back-in-stock/${shipment.order}/create-shipments`),
        {
            onSuccess: () => {
                void queryClient.invalidateQueries('shippable-preorders');
            },
        }
    );

    return (
        <tr>
            <td
                css={css`
                    vertical-align: middle;
                `}
            >
                <input
                    type="checkbox"
                    checked={selected}
                    onChange={ev => {
                        if (ev.target.checked) {
                            setSelected(true);
                        } else {
                            setSelected(false);
                        }
                    }}
                    css={css`
                        -webkit-appearance: none;
                        width: 30px;
                        height: 30px;
                        background: white;
                        border-radius: 2px;
                        border: 2px solid black;

                        &:checked {
                            background: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E")
                                no-repeat center/80%;
                        }
                    `}
                />
            </td>
            <td css={{ padding: '8px 16px' }}>{shipment.order}</td>
            <td>
                <table css={{ td: { padding: '8px 16px' }, width: '100%' }}>
                    {shipment.products.map(product => (
                        <tr>
                            <td>{product.name}</td>
                            <td css={{ width: 40 }}>{product.quantity}</td>
                        </tr>
                    ))}
                </table>
            </td>
            <td css={{ verticalAlign: 'middle', padding: 16 }}>
                {createShipment.isLoading ? (
                    <div>Creating shipment.... Please wait</div>
                ) : (
                    <button
                        css={secondaryButton}
                        onClick={() => {
                            createShipment.mutate();
                        }}
                    >
                        Create
                    </button>
                )}
            </td>
        </tr>
    );
};

async function shippablePreorders(): Promise<Shipment[]> {
    let res = await backendApi.get<Shipment[]>(`/api/v1/preorders-back-in-stock`);
    return res.data;
}

type Shipment = {
    order: string;
    products: Array<{
        quantity: number;
        lineId: number;
        name: string;
    }>;
    gtg: true;
};

function eqSet<T>(as: Set<T>, bs: Set<T>) {
    if (as.size !== bs.size) return false;
    for (const a of as) if (!bs.has(a)) return false;
    return true;
}
