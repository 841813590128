/** @jsxImportSource @emotion/react */

import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { h1, h3, s } from '../styles';
import { css } from '@emotion/react';

export const NoSuchRoute: FC<RouteComponentProps> = () => {
    return (
        <div
            css={css`
                max-width: 640px;
                margin: 0 auto;
                padding: ${s(4)};
                display: flex;
                align-items: center;
                justify-content: center;
            `}
        >
            <div css={h3}>Sorry, could not find what you were looking for</div>
        </div>
    );
};
