import { css } from '@emotion/css';
import React, { FC } from 'react';
import { colors, s } from '../styles';

export const PageWrapper: FC = ({ children }) => {
    return (
        <div
            className={css`
                display: flex;
                margin-bottom: ${s(10)};
                align-content: stretch;
                height: 100%;

                & > div {
                    border-right: 1px solid ${colors.grey};

                    &:last-child {
                        border-right: none;
                        flex-grow: 1;
                    }
                }
            `}
        >
            {children}
        </div>
    );
};
