/** @jsxImportSource @emotion/react */

import React, { FC, useRef, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { gql } from 'graphql-request';
import { css } from '@emotion/react';
import { DatePicker } from 'react-nice-dates';
import { nb } from 'date-fns/locale';
import 'react-nice-dates/build/style.css';
import { colors, filledButton, labelStyle, s, secondaryButton, space } from '../styles';
import { backendApi, graphqlClient } from '../api/backendApi';
import { useErrorHandler } from '../lib/utils';
import { FaPoll, FaRegTrashAlt, FaStar, FaThumbtack } from 'react-icons/fa';
import { PollEdit, useNewPoll, savePoll } from '../components/EditPoll';
import { PaperClipIcon } from '../icons/PaperClipIcon';
import { format } from 'date-fns';
import { transparentize } from 'polished';

// This is a copy from the code from the frontend. It's similar, but not exactly.
// That's why we are not using graphql, but he basic APIs

export const DiscussionTopic: FC<RouteComponentProps & { topicId?: string }> = ({ topicId }) => {
    if (topicId === undefined) throw new Error('TopicID cannot be undefined');
    return (
        <div
            css={css`
                padding: ${s(2)};
            `}
        >
            <Discussion topic={topicId} />
        </div>
    );
};

export const Discussion: FC<{ topic: string }> = ({ topic }) => {
    return (
        <div
            css={css`
                h2 {
                    font-weight: 600;
                    font-size: 18px;
                    color: ${colors.grey};
                }
            `}
        >
            <h2>{topic}</h2>
            <AddPostForm topic={topic} placeholder="Write a new post" label="Write a new post" />
            <Posts topic={topic} />
        </div>
    );
};

async function addPostMutation({
    topic,
    content,
    parent,
    pollId,
    images,
    sendNotifications,
    isCallout,
    calloutType,
    title,
    createdAt,
}: {
    topic: string;
    content: string;
    parent?: number;
    pollId?: number;
    images: string[];
    sendNotifications: boolean;
    isCallout: boolean;
    calloutType: string;
    title: string;
    createdAt: Date | null;
}) {
    await backendApi.post(`/api/v1/community/discussion/${topic}/post`, {
        content,
        parent,
        pollId,
        images,
        sendNotifications,
        isCallout,
        calloutType,
        title,
        createdAt,
    });
}

const AddPostForm: FC<{
    topic: string;
    onSuccess?: () => void;
    parent?: number;
    label: string;
    placeholder: string;
}> = ({ topic, label, placeholder, parent, onSuccess }) => {
    const [createdAt, setCreatedAt] = useState<Date | null>(null);
    const queryClient = useQueryClient();
    const fileUploadRef = useRef<HTMLInputElement>(null);
    const [images, setImages] = useState<string[]>([]);
    let [input, setInput] = useState('');
    let [title, setTitle] = useState('');
    const [calloutType, setCalloutType] = useState('crowdsourcing');
    const [sendNotifications, setSendNotifications] = useState(false);
    const [isCallout, setIsCallout] = useState(false);
    let [showAddPoll, setShowAddPoll] = useState(false);
    let [poll, pollDispatch] = useNewPoll();
    let { mutate, isLoading, error } = useMutation(addPostMutation, {
        onSuccess: () => {
            setIsCallout(false);
            setCreatedAt(null);
            setTitle('');
            setCalloutType('crowdsourcing');
            setInput('');
            setSendNotifications(false);
            setImages([]);
            setShowAddPoll(false);
            pollDispatch({ type: 'reset_to_new' });
            void queryClient.invalidateQueries('discussion_posts');
            if (onSuccess) {
                onSuccess();
            }
        },
    });
    let postMessage = ({ pollId }: { pollId?: number }) => {
        mutate({
            topic,
            content: input,
            sendNotifications,
            parent,
            pollId,
            images,
            isCallout,
            calloutType,
            title,
            createdAt,
        });
    };

    return (
        <>
            {error && (
                <div
                    css={css`
                        padding: ${space(1)} ${space(2)};
                        background: ${colors.error};
                        color: white;
                        border-radius: 5px;
                        max-width: 605px;
                        margin: ${space(2)} 0;
                    `}
                >
                    {(error as Error).toString()}
                </div>
            )}
            <input
                type="file"
                id="fileUpload"
                ref={fileUploadRef}
                css={css`
                    opacity: 0;
                    height: 0px;
                    width: 0px;
                `}
                onChange={ev => {
                    if (ev.target.files?.[0]) {
                        let file = ev.target.files?.[0];
                        if (!file.type.startsWith('image/')) {
                            alert('We only support images');
                            return;
                        }
                        console.log(ev.target.files[0]);
                        const reader = new FileReader();
                        reader.onload = e => {
                            if (e.target?.result) {
                                let result = e.target.result;
                                setImages(old => [...old, result as string]);
                            }
                        };
                        reader.onerror = err => {
                            console.error(err);
                        };
                        reader.readAsDataURL(file);
                    }
                }}
            />
            <form
                onSubmit={ev => {
                    (async () => {
                        ev.preventDefault();
                        let pollId;
                        if (showAddPoll) {
                            pollId = await savePoll(poll);
                        }
                        postMessage({ pollId });
                    })().catch(err => console.error(err));
                }}
                css={css`
                    width: 100%;
                    max-width: ${space(90)};
                    label {
                        display: block;
                    }
                `}
            >
                <label>
                    {label}
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <div
                            css={css`
                                flex-grow: 1;
                                margin-right: ${space(4)};
                                border-radius: 5px;
                                border: 2px solid rgba(0, 0, 0, 0.1);
                            `}
                        >
                            <textarea
                                css={css`
                                    display: block;
                                    height: ${space(16)};
                                    ${label && `margin-top: ${space(2)}`};
                                    padding: ${space(1)} ${space(2)};
                                    border: none;
                                    margin: 0;
                                    width: 100%;
                                    resize: none;
                                `}
                                placeholder={placeholder}
                                value={input}
                                onChange={ev => setInput(ev.target.value)}
                            />
                            <div
                                css={css`
                                    display: flex;
                                    flex-wrap: wrap;
                                    align-items: flex-start;
                                `}
                            >
                                {images.map((src, idx) => (
                                    <img
                                        alt=""
                                        key={idx}
                                        src={src}
                                        css={css`
                                            display: block;
                                            height: 64px;
                                            margin-left: ${space(2)};
                                            margin-bottom: ${space(2)};
                                            border-radius: 3px;
                                        `}
                                    />
                                ))}
                            </div>
                        </div>
                        <button
                            css={css`
                                ${secondaryButton};
                                margin-right: ${space(1)};
                                height: 32px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            `}
                            onClick={ev => {
                                ev.preventDefault();
                                if (fileUploadRef.current) {
                                    fileUploadRef.current.click();
                                }
                            }}
                        >
                            <PaperClipIcon />
                        </button>
                        <button
                            css={css`
                                ${secondaryButton};
                            `}
                            onClick={ev => {
                                ev.preventDefault();
                                setShowAddPoll(true);
                            }}
                        >
                            <FaPoll />
                        </button>
                        <button
                            css={css`
                                ${filledButton};
                            `}
                            disabled={isLoading || input === ''}
                        >
                            {isLoading ? 'loading..' : 'Publish'}
                        </button>
                    </div>
                    {showAddPoll && (
                        <PollEdit state={poll} dispatch={pollDispatch} showQuestion={false} />
                    )}
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={sendNotifications}
                        onChange={ev => setSendNotifications(ev.target.checked)}
                    />{' '}
                    Send email to all users with posts, replies or votes on the discussion
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={isCallout}
                        onChange={ev => setIsCallout(ev.target.checked)}
                    />{' '}
                    Callout
                </label>
                {isCallout && (
                    <div
                        css={css`
                            margin-top: 8px;
                            input,
                            select {
                                display: block;
                                margin-bottom: 8px;
                            }
                        `}
                    >
                        <label>
                            Title
                            <input value={title} onChange={ev => setTitle(ev.target.value)} />
                        </label>
                        <label>
                            Type (Color)
                            <select
                                value={calloutType}
                                onChange={ev => setCalloutType(ev.target.value)}
                            >
                                <option value="crowdsourcing">crowdsourcing</option>
                                <option value="crowdfunding">crowdfunding</option>
                                <option value="preorder">preorder</option>
                                <option value="available">available</option>
                            </select>
                        </label>
                        <label>
                            Override Created At
                            <DatePicker
                                date={createdAt || new Date()}
                                onDateChange={setCreatedAt}
                                locale={nb}
                                format="dd.MM.yyyy HH:mm"
                            >
                                {({ inputProps, focused }) => (
                                    <input
                                        className={'input' + (focused ? ' -focused' : '')}
                                        {...inputProps}
                                    />
                                )}
                            </DatePicker>
                        </label>
                    </div>
                )}
            </form>
        </>
    );
};

type GetPosts = {
    pinned: TPost[];
    starred: TPost[];
    nonPinned: TPost[];
};

type TPost = {
    id: number;
    topic: string;
    createdAt: Date;
    content: string;
    parent: number | null;
    images: string[];
    pinned: boolean;
    starred: boolean;
    isCallout: boolean;
    calloutType?: string;
    title?: string;
    likes: {
        totalCount: number;
    };
    userLikes: {
        totalCount: number;
    };
    user?: {
        fullName?: string | null;
        admin: boolean;
    };
    poll?: {
        id: number;
        votes: {
            totalCount: number;
        };
        userVotes: {
            nodes: Array<{
                alternativeId: number;
            }>;
        };
        pollAlternatives: {
            nodes: Array<{
                id: number;
                color: string;
                text: string;
                votes: {
                    totalCount: number;
                };
            }>;
        };
    };
    children?: {
        nodes: TPost[];
    };
};

export const Posts: FC<{ topic: string }> = ({ topic }) => {
    const { data, error, isLoading } = useQuery(['discussion_posts', { topic }], async () => {
        let res = await backendApi.get<GetPosts>(`/api/v1/community/discussion/${topic}/post`);
        return res.data;
    });

    if (error) {
        return <div>{(error as Error).toString()}</div>;
    }

    if (isLoading || !data) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {data.starred.length > 0 && (
                <PostGroupHeading name="Starred" color={'orange'}>
                    <PostGroup topic={topic} posts={data.starred} />
                </PostGroupHeading>
            )}
            {data.pinned.length > 0 && (
                <PostGroupHeading name="Pinned" color={colors.crowdsourcing}>
                    <PostGroup topic={topic} posts={data.pinned} />
                </PostGroupHeading>
            )}
            <PostGroup topic={topic} posts={data.nonPinned} />
        </div>
    );
};

const PostGroupHeading: FC<{ name: string; color: string }> = ({ name, color, children }) => {
    return (
        <div
            css={css`
                margin-top: ${s(8)};
                border-left: 2px solid ${color};
                padding-left: ${s(3)};
                margin-top: 10px;
            `}
        >
            <div
                css={css`
                    padding: ${s(0)} ${s(2)};
                    line-height: ${s(4)};
                    background: ${color};
                    color: white;
                    display: inline-block;
                    margin-left: calc(-${s(3)} - 2px);
                    border-radius: 3px;
                    margin-top: -10px;
                    ${labelStyle};
                `}
            >
                {name}
            </div>
            {children}
        </div>
    );
};

export const PostGroup: FC<{ topic: string; posts: TPost[] }> = ({ topic, posts }) => {
    return (
        <div
            css={css`
                max-width: 544px;
            `}
        >
            {posts.map(post => (
                <div
                    css={css`
                        margin: ${space(6)} 0;
                    `}
                    key={post.id}
                >
                    <Post
                        topic={topic}
                        post={post}
                        hasChildren={post.children?.nodes?.length !== 0}
                    />
                </div>
            ))}
        </div>
    );
};

const DELETE_POST = gql`
    mutation DeletePost($postId: Int!) {
        deleteDiscussionPost(input: { id: $postId }) {
            deletedDiscussionPostNodeId
        }
    }
`;

const SET_POST_PINNED = gql`
    mutation SetPostPinned($postId: Int!, $pinned: Boolean!) {
        updateDiscussionPost(input: { patch: { pinned: $pinned }, id: $postId }) {
            clientMutationId
        }
    }
`;

const SET_POST_STARRED = gql`
    mutation SetPostStarred($postId: Int!, $starred: Boolean!) {
        updateDiscussionPost(input: { patch: { starred: $starred }, id: $postId }) {
            clientMutationId
        }
    }
`;

async function doDeletePost({ postId }: { postId: number }) {
    await graphqlClient.request(DELETE_POST, { postId });
}

function calloutColor(type?: string): string {
    if (type === 'crowdfunding') {
        return colors.crowdfunding;
    }
    if (type === 'crowdsourcing') {
        return colors.crowdsourcing;
    }
    return '#2b2929';
}

export const Post: FC<{ topic: string; post: TPost; hasChildren: boolean }> = ({
    topic,
    post,
    hasChildren,
}) => {
    let queryClient = useQueryClient();
    let handleError = useErrorHandler();
    const deletePost = useMutation(doDeletePost, {
        onSuccess: () => {
            void queryClient.invalidateQueries('discussion_posts');
        },
    });

    const deleteClick = () => {
        if (hasChildren) {
            window.alert('The post has children. Delete them first.');
            return;
        }

        if (window.confirm(`Are you sure you want to delete the post by ${post.user?.fullName}`)) {
            // Check that the post does not have any replies
            deletePost.mutateAsync({ postId: post.id }).catch(err => console.error(err));
        }
    };

    const togglePinClick = () => {
        graphqlClient
            .request(SET_POST_PINNED, { postId: post.id, pinned: !post.pinned })
            .then(() => {
                void queryClient.invalidateQueries('discussion_posts');
            })
            .catch(handleError);
    };
    const toggleStarClick = () => {
        graphqlClient
            .request(SET_POST_STARRED, { postId: post.id, starred: !post.starred })
            .then(() => {
                void queryClient.invalidateQueries('discussion_posts');
            })
            .catch(handleError);
    };

    let color = post.isCallout ? calloutColor(post.calloutType) : '#292b2b';

    return (
        <>
            <div
                css={css`
                    font-weight: 600;
                    margin-bottom: ${space(2)};
                `}
            >
                {post.user?.fullName || 'Anonymous'}{' '}
                <span
                    css={css`
                        font-weight: 400;
                        color: #444;
                    `}
                >
                    {format(new Date(post.createdAt), 'dd.MM.yyyy HH:MM')}
                </span>
            </div>
            <div
                css={css`
                    display: flex;
                `}
            >
                <button onClick={deleteClick}>
                    <FaRegTrashAlt />
                </button>
                <button onClick={togglePinClick}>
                    <FaThumbtack />
                </button>
                <button onClick={toggleStarClick}>
                    <FaStar />
                </button>
            </div>
            <div
                css={css`
                    margin-bottom: ${space(3)};
                    color: ${color};

                    ${post.isCallout &&
                    css`
                        background-color: ${transparentize(0.9, color)};
                        padding: ${s(1)};
                    `};
                `}
            >
                {post.isCallout && (
                    <h3
                        css={css`
                            font-size: 32px;
                        `}
                    >
                        {post.title}
                    </h3>
                )}
                {post.content.split('\n\n').map((paragraph, idx) => (
                    <p
                        css={css`
                            margin: ${space(2)} 0;
                        `}
                        key={idx}
                    >
                        {paragraph.split('\n').map((line, idx) => (
                            <React.Fragment key={idx}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </p>
                ))}
            </div>
            <div
                css={css`
                    display: flex;
                    flex-wrap: wrap;
                `}
            >
                {post.images.map(image => (
                    <a href={`${process.env.REACT_APP_IMAGE_HOST}${image}`}>
                        <img
                            alt=""
                            css={css`
                                border-radius: 5px;
                                margin-bottom: ${space(3)};
                                cursor: pointer;
                            `}
                            key={image}
                            src={`${process.env.REACT_APP_IMAGE_HOST}${image}?height=200`}
                        />
                    </a>
                ))}
            </div>
            <PostPoll {...{ post }} />
            <div
                css={css`
                    border-left: 2px solid rgba(0, 0, 0, 0.1);
                    padding: 0 ${space(3)};
                `}
            >
                {(post.children?.nodes || []).map(child => (
                    <Post topic={topic} key={child.id} post={child} hasChildren={false} />
                ))}
                <AddReply topic={topic} parent={post.id} />
            </div>
        </>
    );
};

const PostPoll: FC<{ post: TPost }> = ({ post }) => {
    if (!post.poll) {
        return null;
    }

    let totalVotes = post.poll.votes.totalCount;
    return (
        <div
            css={css`
                margin-bottom: ${space(2)};
                display: flex;
                align-items: flex-start;
                flex-direction: column;
            `}
        >
            {post.poll.pollAlternatives.nodes.map((alternative, idx) => (
                <div
                    css={css`
                        ${secondaryButton};
                        cursor: default;
                        margin-bottom: ${space(2)};
                        width: auto;
                    `}
                    key={alternative.id}
                >
                    <span
                        css={css`
                            ${labelStyle};
                            display: inline-block;
                            width: ${space(2)};
                            height: ${space(2)};
                            line-height: ${space(2)};
                            background: #d6d6d6;
                            border-radius: 3px;
                            margin-right: ${space(2)};
                            text-align: center;
                            color: #2b2929;
                        `}
                    >
                        {idx + 1}
                    </span>
                    {alternative.text}{' '}
                    <span
                        css={css`
                            color: #737373;
                        `}
                    >
                        {alternative.votes.totalCount} votes{' '}
                        {totalVotes > 0 && (
                            <>({Math.round((alternative.votes.totalCount / totalVotes) * 100)} %)</>
                        )}
                    </span>
                </div>
            ))}
        </div>
    );
};

export const AddReply: FC<{ topic: string; parent: number }> = ({ topic, parent }) => {
    let [showForm, setShowForm] = useState(false);

    if (showForm) {
        return (
            <AddPostForm
                onSuccess={() => setShowForm(false)}
                topic={topic}
                parent={parent}
                placeholder="Write a comment"
                label=""
            />
        );
    }

    return (
        <button
            css={css`
                color: #c0c0c0;
            `}
            onClick={() => {
                setShowForm(true);
            }}
        >
            Write a comment...
        </button>
    );
};
