/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { AxiosError } from 'axios';
import React, { FC, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { backendApi } from '../api/backendApi';
import { h2 } from '../styles';
import { Button, Buttons, Field, Form } from './Form';

async function loginMutation({
    email,
    password,
}: {
    email: string;
    password: string;
}): Promise<void> {
    if (email && password) {
        try {
            await backendApi.post('/api/v1/login', { email, password });
        } catch (err) {
            let maybeMessage = (err as AxiosError).response?.data?.message;
            if (maybeMessage) {
                throw new Error(maybeMessage as string | undefined);
            }
            throw err;
        }
    } else {
        throw new Error('Email and password required');
    }
}

export const Login: FC<{ onSuccess: () => void }> = ({ onSuccess }) => {
    let [email, setEmail] = useState('');
    let [password, setPassword] = useState('');
    const queryClient = useQueryClient();

    let { mutate, isLoading, error } = useMutation(loginMutation, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(
                'current_user',
                {
                    refetchInactive: true,
                },
                {
                    throwOnError: true,
                }
            );
            if (onSuccess) {
                onSuccess();
            }
        },
    });

    const emailError = !email && 'Email required';
    const passwordError = !password && 'Password required';

    const hasErrors = emailError || passwordError;

    return (
        <div
            css={css`
                max-width: 640px;
                margin: 0 auto;
            `}
        >
            <h1 css={h2}>Login</h1>
            <Form
                error={error as Error}
                onSubmit={() => {
                    if (!hasErrors) {
                        mutate({ email, password });
                    }
                }}
            >
                <Field label="e-mail" error={emailError}>
                    <input
                        placeholder="Enter e-mail..."
                        value={email}
                        type="email"
                        autoComplete="email"
                        onChange={ev => setEmail(ev.target.value)}
                    />
                </Field>
                <Field label="password" error={passwordError}>
                    <input
                        placeholder="Enter password..."
                        type="password"
                        value={password}
                        autoComplete="current-password"
                        onChange={ev => setPassword(ev.target.value)}
                    />
                </Field>
                <Buttons>
                    <Button disabled={isLoading}>{isLoading ? 'Loading...' : 'Log in'}</Button>
                </Buttons>
            </Form>
        </div>
    );
};
