/** @jsxImportSource @emotion/react */

import { RouteComponentProps, Router } from '@reach/router';
import React, { FC } from 'react';
import { MenuList, MenuListExternalLink, MenuListLink } from '../components/MenuList';
import { PageWrapper } from '../components/PageWrapper';
import { useSanityGraphQlQuery } from '../api/backendApi';
import { css } from '@emotion/react';
import { h3, s } from '../styles';
import { DiscussionTopic } from './DiscussionTopic';
import { CrowdfundingOrders } from './CrowdfundingOrders';

const PRODUCT_QUERY = `
    query SanityProductQuery($sanityId: ID!) {
        Product(id: $sanityId) {
            _id
            title {
                nb
            }
            slug {
                nb {
                    current
                }
            }
            centraId
        }
    }
`;

type SanityProductQuery = {
    Product: {
        _id: string;
        title: {
            nb: string;
        };
        slug: {
            nb: {
                current: string;
            };
        };
        centraId: string;
    };
};

export const Product: FC<RouteComponentProps & { sanityId?: string }> = ({ sanityId }) => {
    let { isLoading, error, data } = useSanityGraphQlQuery<SanityProductQuery>(
        PRODUCT_QUERY,
        'sanity_product',
        { sanityId }
    );

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.toString()}</div>;
    if (!data) return <div>No data</div>;
    let product = data.Product;

    return (
        <PageWrapper>
            <div
                css={css`
                    padding: ${s(2)};
                `}
            >
                <h1 css={h3}>{product.title.nb}</h1>
                <MenuList>
                    <MenuListLink to={`discussion`}>Discussion</MenuListLink>
                    <MenuListLink to={`crowdfunding_orders`}>Crowdfunding Orders</MenuListLink>
                    <MenuListExternalLink
                        href={`https://www.northernplayground.no/nb/shop/${product.slug.nb.current}`}
                    >
                        To webshop
                    </MenuListExternalLink>
                    <MenuListExternalLink
                        href={`https://northernplayground.centra.com/amsnorplay2020/catalog?id=${product.centraId}`}
                    >
                        To Centra
                    </MenuListExternalLink>
                    <MenuListExternalLink
                        href={`https://northernplayground.sanity.studio/desk/product;${product._id}`}
                    >
                        To Sanity
                    </MenuListExternalLink>
                </MenuList>
            </div>
            <Router>
                <DiscussionTopic path="discussion" topicId={`product_${product._id}`} />
                <CrowdfundingOrders path="crowdfunding_orders/*" centraId={product.centraId} />
            </Router>
        </PageWrapper>
    );
};
