import { useQuery } from 'react-query';
import { Link, RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';
import { MenuList, MenuListLink } from '../components/MenuList';
import { PageWrapper } from '../components/PageWrapper';
import { graphqlClient, sanityGraphqlClient } from '../api/backendApi';

const PRODUCTS_QUERY = `
    query SanityProductsQuery {
        allProduct {
            _id
            title {
                nb
            }
        }
    }
`;

type SanityProductsQuery = {
    allProduct: Array<{
        _id: string;
        title: {
            nb: string;
        };
    }>;
};

async function getProducts(): Promise<SanityProductsQuery> {
    return await sanityGraphqlClient.request(PRODUCTS_QUERY);
}

export const Products: FC<RouteComponentProps> = ({ children }) => {
    let { isLoading, error, data } = useQuery<SanityProductsQuery, Error>(
        ['sanity_products'],
        getProducts
    );

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.toString()}</div>;
    if (!data) return <div>No data</div>;

    return (
        <PageWrapper>
            <MenuList>
                {data.allProduct.map(product => (
                    <MenuListLink to={product._id}>{product.title.nb}</MenuListLink>
                ))}
            </MenuList>
            {children}
        </PageWrapper>
    );
};
