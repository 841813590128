/** @jsxImportSource @emotion/react */

import React, { FC, useState } from 'react';
import * as Sentry from '@sentry/react';
import { RouteComponentProps, useParams } from '@reach/router';
import { CrowdfundingOrderQuery } from './__generated__/CrowdfundingOrderQuery';
import { gql } from 'graphql-request';
import { backendApi, graphqlClient, useGraphQlQuery } from '../api/backendApi';
import { colors, filledButton, s, secondaryButton } from '../styles';
import { css } from '@emotion/react';
import { useMutation, useQueryClient } from 'react-query';
import { Button, Buttons, Field, Form } from '../components/Form';
import { AxiosError } from 'axios';

const ORDER_QUERY = gql`
    query CrowdfundingOrderQuery($orderId: Int!) {
        crowdfundingOrder(id: $orderId) {
            id
            createdAt
            cancelled
            centraOrderId
            invoiceAddress
            deliveryAddress
            productId
            shippingValue
            lines: crowdfundingOrderLinesByOrderId {
                nodes {
                    qty
                    unitPrice
                    sku
                }
            }
            payment {
                currency
                regularOrderId
                paymentVouchers {
                    nodes {
                        description
                        priceOff
                        voucher
                    }
                }
                adyenPspReference
            }
            adyenPaymentStatus
            adyenPspReference
            voucherDiscount
        }
    }
`;

const Property: FC<{ name: string; value: string | number | null }> = ({ name, value }) => (
    <div
        css={css`
            padding: ${s(1)} 0;
            display: flex;

            &:hover {
                background: ${colors.lightgrey};
            }
        `}
    >
        <div
            css={css`
                width: 200px;
            `}
        >
            {name}
        </div>
        <strong>{value}</strong>
    </div>
);

const Section: FC = ({ children }) => (
    <div
        css={css`
            margin-bottom: ${s(2)};
        `}
    >
        {children}
    </div>
);

async function finalizeOrder(orderId: number) {
    if (window.confirm('Are you sure you want to confirm the order? This cannot be undone.')) {
        await backendApi
            .post(`/api/v1/admin/finalize_crowdfunding_payment/${orderId}`)
            .catch(err_ => {
                let err = err_ as AxiosError;
                Sentry.captureException(err);
                if (err.response?.data) {
                    alert(err.response.data);
                } else {
                    alert(err.message);
                }
            });
    }
}

async function retryPayment(orderId: number) {
    if (window.confirm('Are you sure you want to retry the payment? This cannot be undone.')) {
        await backendApi.post(`/api/v1/admin/retry_crowdfunding_payment/${orderId}`).catch(err_ => {
            let err = err_ as AxiosError;
            Sentry.captureException(err);
            if (err.response?.data) {
                alert(err.response.data);
            } else {
                alert(err.message);
            }
        });
    }
}

async function setOrderCancelled(orderId: number, cancelled: boolean) {
    await graphqlClient
        .request(
            gql`
                mutation SetOrderCancelled($orderId: Int!, $cancelled: Boolean!) {
                    updateCrowdfundingOrder(
                        input: { patch: { cancelled: $cancelled }, id: $orderId }
                    ) {
                        clientMutationId
                    }
                }
            `,
            { orderId, cancelled }
        )
        .catch(err => {
            Sentry.captureException(err);
            alert(err.message);
        });
}

export const CrowdfundingOrder: FC<RouteComponentProps> = () => {
    let { orderId } = useParams();
    let { isLoading, error, data } = useGraphQlQuery<CrowdfundingOrderQuery>(
        ORDER_QUERY,
        'crowdfunding_order',
        {
            orderId: parseInt(orderId as string) || -1,
        }
    );
    const [isEditing, setIsEditing] = useState(false);
    const queryClient = useQueryClient();

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error</div>;
    if (!data) return <div>No such order</div>;

    let order = data.crowdfundingOrder;

    if (!order) return <div>No such order</div>;

    orderId = order.id;

    if (isEditing) {
        return <EditOrder order={order} close={() => setIsEditing(false)} />;
    }

    return (
        <div
            css={css`
                padding: ${s(1)} ${s(2)};
            `}
        >
            <div
                css={css`
                    display: flex;
                    margin-bottom: ${s(3)};
                `}
            >
                {!order.centraOrderId && (
                    <button
                        css={css`
                            ${filledButton};
                            margin-right: ${s(1)};
                        `}
                        onClick={() =>
                            finalizeOrder(orderId as number).then(() => {
                                void queryClient.invalidateQueries('crowdfunding_order');
                                void queryClient.invalidateQueries('crowdfunding_orders');
                            })
                        }
                    >
                        Finalize order
                    </button>
                )}

                {order.centraOrderId && order.adyenPaymentStatus !== 'Authorised' && (
                    <button
                        css={css`
                            ${filledButton};
                            margin-right: ${s(1)};
                        `}
                        onClick={() =>
                            retryPayment(orderId as number).then(() => {
                                void queryClient.invalidateQueries('crowdfunding_order');
                                void queryClient.invalidateQueries('crowdfunding_orders');
                            })
                        }
                    >
                        Retry payment
                    </button>
                )}

                {!order.centraOrderId && (
                    <button
                        css={css`
                            ${filledButton};
                            margin-right: ${s(1)};
                        `}
                        onClick={() => {
                            setIsEditing(true);
                        }}
                    >
                        Edit order
                    </button>
                )}

                {!order.centraOrderId && (
                    <button
                        css={css`
                            ${filledButton};
                            margin-right: ${s(1)};
                        `}
                        onClick={() => {
                            setOrderCancelled(orderId as number, !order?.cancelled)
                                .then(() => {
                                    void queryClient.invalidateQueries('crowdfunding_order');
                                    void queryClient.invalidateQueries('crowdfunding_orders');
                                })
                                .catch(err => {
                                    console.error(`error in setOrderCancelled: ${err}`);
                                });
                        }}
                    >
                        {order.cancelled ? 'Reactivate order' : 'Cancel order'}
                    </button>
                )}
            </div>
            <Section>
                {order.cancelled && (
                    <div
                        css={css`
                            background-color: ${colors.error};
                            padding: ${s(2)} ${s(3)};
                            color: white;
                            display: inline-block;
                            margin-bottom: ${s(2)};
                        `}
                    >
                        Order is cancelled
                    </div>
                )}
                <Property name="id" value={order.id} />

                <Property name="Created" value={order.createdAt} />

                <Property name="Email (Delivery)" value={order.deliveryAddress.email} />
                <Property name="Email (Invoice)" value={order.invoiceAddress.email} />

                {order.lines.nodes.map(line => (
                    <div
                        css={css`
                            border-left: 1px solid ${colors.grey};
                            padding-left: 16px;
                            margin: 16px 0;
                        `}
                    >
                        <Property name="Sku" value={line.sku} />
                        <Property name="Qty" value={line.qty} />
                        <Property
                            name="Price per item"
                            value={`${line.unitPrice / 100} ${order?.payment?.currency}`}
                        />
                        <Property
                            name="Total price"
                            value={`${(line.unitPrice * line.qty) / 100} ${
                                order?.payment?.currency
                            }`}
                        />
                    </div>
                ))}
                <Property
                    name="Shipping"
                    value={`${order.shippingValue / 100} ${order.payment?.currency}`}
                />
                {order.voucherDiscount ? (
                    <Property
                        name="Voucher Discount"
                        value={`-${order.voucherDiscount / 100} ${order.payment?.currency}`}
                    />
                ) : null}
                {(order.payment?.paymentVouchers.nodes || []).map(voucher => (
                    <Property
                        name="Voucher"
                        value={`${voucher.voucher} (${voucher.description}, ${voucher.priceOff} ${order?.payment?.currency})`}
                    />
                ))}
            </Section>

            <Section>
                <Property name="Centra OrderID" value={order.centraOrderId} />
                <Property name="Payment Status" value={order.adyenPaymentStatus} />
                <Property name="Payment Reference" value={order.adyenPspReference} />
            </Section>
            <Section>
                <Property
                    name="Invoice Name"
                    value={`${order.invoiceAddress.firstName} ${order.invoiceAddress.lastName}`}
                />
                <Property name="Innvoice State" value={order.invoiceAddress.state} />
                <Property name="Innvoice City" value={order.invoiceAddress.city} />
                <Property name="Innvoice Phone" value={order.invoiceAddress.tele} />
                <Property name="Innvoice Address" value={order.invoiceAddress.address} />
                <Property name="Innvoice Country" value={order.invoiceAddress.country} />
                <Property name="Innvoice ZipCode" value={order.invoiceAddress.zipcode} />
            </Section>
            <Section>
                <Property
                    name="Shipping Name"
                    value={`${order.deliveryAddress.firstName} ${order.deliveryAddress.lastName}`}
                />
                <Property name="Shipping State" value={order.deliveryAddress.state} />
                <Property name="Shipping City" value={order.deliveryAddress.city} />
                <Property name="Shipping Phone" value={order.deliveryAddress.tele} />
                <Property name="Shipping Address" value={order.deliveryAddress.address} />
                <Property name="Shipping Country" value={order.deliveryAddress.country} />
                <Property name="Shipping ZipCode" value={order.deliveryAddress.zipcode} />
            </Section>
            <Section>
                <Property name="Regular sibling-order" value={`${order.payment?.regularOrderId}`} />
                <Property
                    name="Tokenization psp-reference"
                    value={`${order.payment?.adyenPspReference}`}
                />
            </Section>
        </div>
    );
};

const EditOrder: FC<{
    close: () => void;
    order: NonNullable<CrowdfundingOrderQuery['crowdfundingOrder']>;
}> = ({ close, order }) => {
    const hasErrors = null;

    const [invoiceEmail, setInvoiceEmail] = useState(order.invoiceAddress.email);
    const [invoiceFirstName, setInvoiceFirstName] = useState(order.invoiceAddress.firstName);
    const [invoiceLastName, setInvoiceLastName] = useState(order.invoiceAddress.lastName);
    const [invoiceState, setInvoiceState] = useState(order.invoiceAddress.state);
    const [invoiceCity, setInvoiceCity] = useState(order.invoiceAddress.city);
    const [invoiceTele, setInvoiceTele] = useState(order.invoiceAddress.tele);
    const [invoiceAddress, setInvoiceAddress] = useState(order.invoiceAddress.address);
    const [invoiceCountry, setInvoiceCountry] = useState(order.invoiceAddress.country);
    const [invoiceZipcode, setInvoiceZipcode] = useState(order.invoiceAddress.zipcode);

    const [deliveryEmail, setDeliveryEmail] = useState(order.deliveryAddress.email);
    const [deliveryFirstName, setDeliveryFirstName] = useState(order.deliveryAddress.firstName);
    const [deliveryLastName, setDeliveryLastName] = useState(order.deliveryAddress.lastName);
    const [deliveryState, setDeliveryState] = useState(order.deliveryAddress.state);
    const [deliveryCity, setDeliveryCity] = useState(order.deliveryAddress.city);
    const [deliveryTele, setDeliveryTele] = useState(order.deliveryAddress.tele);
    const [deliveryAddress, setDeliveryAddress] = useState(order.deliveryAddress.address);
    const [deliveryCountry, setDeliveryCountry] = useState(order.deliveryAddress.country);
    const [deliveryZipcode, setDeliveryZipcode] = useState(order.deliveryAddress.zipcode);

    const queryClient = useQueryClient();

    let updateMutation = useMutation(
        async () => {
            try {
                if (deliveryEmail !== order.deliveryAddress.email) {
                    await backendApi.post(`/api/v1/admin/add_email_from_crowdfunding_order`, {
                        lines: order.lines.nodes.map(line => ({ sku: line.sku })),
                        productId: order.productId,
                        email: deliveryEmail,
                        firstName: deliveryFirstName,
                        lastName: deliveryLastName,
                    });
                }
            } catch (err) {
                throw new Error(`Could not update mailchimp email: ${err}`);
            }

            await graphqlClient.request(
                gql`
                    mutation UpdateCrowdfundingOrder(
                        $orderId: Int!
                        $deliveryAddress: JSON!
                        $invoiceAddress: JSON!
                    ) {
                        updateCrowdfundingOrder(
                            input: {
                                patch: {
                                    deliveryAddress: $deliveryAddress
                                    invoiceAddress: $invoiceAddress
                                }
                                id: $orderId
                            }
                        ) {
                            clientMutationId
                        }
                    }
                `,
                {
                    deliveryAddress: {
                        ...order.deliveryAddress,
                        email: deliveryEmail,
                        firstName: deliveryFirstName,
                        lastName: deliveryLastName,
                        state: deliveryState,
                        city: deliveryCity,
                        tele: deliveryTele,
                        address: deliveryAddress,
                        country: deliveryCountry,
                        zipcode: deliveryZipcode,
                    },
                    invoiceAddress: {
                        ...order.invoiceAddress,
                        email: invoiceEmail,
                        firstName: invoiceFirstName,
                        lastName: invoiceLastName,
                        state: invoiceState,
                        city: invoiceCity,
                        tele: invoiceTele,
                        address: invoiceAddress,
                        country: invoiceCountry,
                        zipcode: invoiceZipcode,
                    },
                    orderId: order.id,
                }
            );
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries([
                    'crowdfunding_order',
                    {
                        orderId: order.id,
                    },
                ]);
                close();
            },
        }
    );

    return (
        <Form
            error={updateMutation.error as Error}
            onSubmit={() => {
                if (!hasErrors) {
                    updateMutation.mutate();
                }
            }}
            css={css`
                padding: ${s(1)} ${s(2)};
                max-width: 800px;

                h2 {
                    font-weight: bold;
                    font-size: 32px;
                    margin: 24px 0 16px;
                }
            `}
        >
            <div
                css={css`
                    display: flex;
                    margin-bottom: ${s(3)};
                `}
            >
                <button
                    disabled={updateMutation.isLoading}
                    css={css`
                        ${secondaryButton};
                        margin-right: ${s(1)};
                    `}
                    type="button"
                    onClick={ev => {
                        ev.preventDefault();
                        close();
                    }}
                >
                    Cancel
                </button>

                <button
                    disabled={updateMutation.isLoading}
                    css={css`
                        ${filledButton};
                        margin-right: ${s(1)};
                    `}
                    type="submit"
                >
                    Save
                </button>
            </div>

            <fieldset disabled={updateMutation.isLoading}>
                <h2>Delivery Address</h2>
                <Field label="e-mail">
                    <input
                        value={deliveryEmail}
                        autoComplete="off"
                        onChange={ev => setDeliveryEmail(ev.target.value)}
                    />
                </Field>
                <Field label="FirstName">
                    <input
                        value={deliveryFirstName}
                        autoComplete="off"
                        onChange={ev => setDeliveryFirstName(ev.target.value)}
                    />
                </Field>
                <Field label="LastName">
                    <input
                        value={deliveryLastName}
                        autoComplete="off"
                        onChange={ev => setDeliveryLastName(ev.target.value)}
                    />
                </Field>
                <Field label="State">
                    <input
                        value={deliveryState}
                        autoComplete="off"
                        onChange={ev => setDeliveryState(ev.target.value)}
                    />
                </Field>
                <Field label="City">
                    <input
                        value={deliveryCity}
                        autoComplete="off"
                        onChange={ev => setDeliveryCity(ev.target.value)}
                    />
                </Field>
                <Field label="Tele">
                    <input
                        value={deliveryTele}
                        autoComplete="off"
                        onChange={ev => setDeliveryTele(ev.target.value)}
                    />
                </Field>
                <Field label="Address">
                    <input
                        value={deliveryAddress}
                        autoComplete="off"
                        onChange={ev => setDeliveryAddress(ev.target.value)}
                    />
                </Field>
                <Field label="Country">
                    <input
                        value={deliveryCountry}
                        autoComplete="off"
                        onChange={ev => setDeliveryCountry(ev.target.value)}
                    />
                </Field>
                <Field label="Zipcode">
                    <input
                        value={deliveryZipcode}
                        autoComplete="off"
                        onChange={ev => setDeliveryZipcode(ev.target.value)}
                    />
                </Field>

                <h2>Invoice Address</h2>
                <Field label="e-mail">
                    <input
                        value={invoiceEmail}
                        autoComplete="off"
                        onChange={ev => setInvoiceEmail(ev.target.value)}
                    />
                </Field>
                <Field label="FirstName">
                    <input
                        value={invoiceFirstName}
                        autoComplete="off"
                        onChange={ev => setInvoiceFirstName(ev.target.value)}
                    />
                </Field>
                <Field label="LastName">
                    <input
                        value={invoiceLastName}
                        autoComplete="off"
                        onChange={ev => setInvoiceLastName(ev.target.value)}
                    />
                </Field>
                <Field label="State">
                    <input
                        value={invoiceState}
                        autoComplete="off"
                        onChange={ev => setInvoiceState(ev.target.value)}
                    />
                </Field>
                <Field label="City">
                    <input
                        value={invoiceCity}
                        autoComplete="off"
                        onChange={ev => setInvoiceCity(ev.target.value)}
                    />
                </Field>
                <Field label="Tele">
                    <input
                        value={invoiceTele}
                        autoComplete="off"
                        onChange={ev => setInvoiceTele(ev.target.value)}
                    />
                </Field>
                <Field label="Address">
                    <input
                        value={invoiceAddress}
                        autoComplete="off"
                        onChange={ev => setInvoiceAddress(ev.target.value)}
                    />
                </Field>
                <Field label="Country">
                    <input
                        value={invoiceCountry}
                        autoComplete="off"
                        onChange={ev => setInvoiceCountry(ev.target.value)}
                    />
                </Field>
                <Field label="Zipcode">
                    <input
                        value={invoiceZipcode}
                        autoComplete="off"
                        onChange={ev => setInvoiceZipcode(ev.target.value)}
                    />
                </Field>
            </fieldset>
            <Buttons>
                <Button type="submit" disabled={updateMutation.isLoading}>
                    Save
                </Button>
            </Buttons>
        </Form>
    );
};
